import F from '../../helper/function'

const initialState = () => ({
  init: {}
})

const state = initialState()

const actions = {}

const getters = {
  init (state) {
    return state.init
  },
  genderOptions (state) {
    var genders = []
    state.init.genders.map((val) => {
      genders.push(
        {
          id: val.id,
          name: val.name[state.init.current_lang]
        }
      )
    })
    return genders
  },
  eventTypeOptions (state) {
    var eventTypes = []
    state.init.event_type.map((val) => {
      eventTypes.push(
        {
          id: val.id,
          name: val.name[state.init.current_lang]
        }
      )
    })
    return eventTypes
  },
  crntLang (state) {
    return state.init.current_lang
  }
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  INIT (state, init) {
    var currentLang = F.getCookie('current_lang')
    if (currentLang !== '') {
      init.current_lang = currentLang
    }
    state.init = init
  },
  SET_LANGUAGE (state, lang) {
    state.init.current_lang = lang
    F.setCookie('current_lang', lang)
  },
  EMPTY_FRIENDS_NOTIFICATION (state) {
    state.init.friends_notifications = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
