import API from './api'
import store from '../store'

/* eslint-disable */
export default {
  login(data) {
    return new Promise((resolve, reject) => {
      API.post('sign-in', data).then((response) => {
        if (response && response.data.data && response.data.data.user_type == 1) {
          this.setUser(response.data.data)
          API.get('init').then((resp) => {
            store.commit('Init/INIT', resp.data)
          })
          resolve(response.data.data)
        } else {
          resolve(false)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  logout(data) {
    return new Promise((resolve) => {
      API.post('logout', data).then((response) => {
        store.dispatch('reset')
        API.get('init').then((resp) => {
          store.commit('Init/INIT', resp.data)
        })
        // store.commit('Init/INIT', response.data.data)
        resolve()
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.errors.join('<br>'),
          type: 'warning'
        })
      })
    })
  },
  setUser(user) {
    if (user) {
      console.log('setUserIf');
      store.commit('User/SET_LOGIN_STATUS', true)
    } else {
      store.commit('User/SET_LOGIN_STATUS', false)
    }
    store.commit('User/SET_USER', user)
  },
  validateToken() {
    return new Promise((resolve) => {
      // TO DO reject
      resolve()
    })
  },
  get(url) {
    return new Promise((resolve, reject) => {
      API.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.data.errors.join('<br>'),
          type: 'warning'
        })
        reject(error)
      })
    })
  },
  post(url, data) {
    return new Promise((resolve, reject) => {
      API.post(url, data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
