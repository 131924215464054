class F {
  message (response) {
    var messageType = 'success'
    var message = []
    var types = ['danger', 'warning', 'success']

    if ((typeof response.type !== 'undefined' && types.includes(response.type))) {
      messageType = response.type
      message = response.message
    } else if (response.status === 200) {
      message = response.data.message
    } else {
      var erroeMessages = Object.values(response.response.data.errors)
      erroeMessages.forEach((element, index) => {
        let eMessage = ''
        if (element.isArray()) {
          eMessage = element[0]
        } else {
          eMessage = element
        }
        erroeMessages[index] = eMessage
      })
      message = erroeMessages
      messageType = response.response.data.type
    }
    return {
      message: message,
      type: messageType
    }
  }

  setCookie (cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

  getCookie (cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
}

const f = new F()
export default f
