import Vue from 'vue'
import Vuex from 'vuex'

import Storage from '@/helper/Storage'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    reset ({ commit }) {
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
      Storage.Clear()
    }
  },
  modules: modules,
})

