import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import api from "@/api/http";
import API from "@/api/api";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
  // You can set your default options here
};

Vue.prototype.$api = api

Vue.config.productionTip = false
Vue.use(Toast);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
