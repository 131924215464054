<template>
  <v-navigation-drawer :value="isDrawerOpen" app floating width="260" class="app-navigation-menu" :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)">
    <!-- Navigation Header -->
    <div class="items-center pt-5 pb-2 vertical-nav-header d-flex ps-6 pe-5">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img :src="require('@/assets/images/logos/logo.png')" max-height="30px" max-width="30px" alt="logo" contain
          eager class="app-logo me-3"></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            FWITTY
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="pr-5 vertical-nav-menu-items">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <!-- <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link> -->
      <nav-menu-group title="Users" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Users" :to="{ name: 'UserList' }"></nav-menu-link>
        <nav-menu-link title="User Categories" :to="{ name: 'UserCategoryList' }"></nav-menu-link>
        <nav-menu-link title="User Recently Expire" :to="{ name: 'UserExpList' }"></nav-menu-link>
        <nav-menu-link title="Upcomming expired" :to="{ name: 'UserUpExpList' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Packages" :icon="icons.mdiPackage">
        <nav-menu-link title="Packages" :to="{ name: 'PackageList' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Posts" :icon="icons.mdiFileOutline">
        <nav-menu-link title="Posts" :to="{ name: 'PostList' }"></nav-menu-link>
        <nav-menu-link title="Categories" :to="{ name: 'PostCategoryList' }"></nav-menu-link>
        <nav-menu-link title="Categories With Posts" :to="{ name: 'PostCategoryListData' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Videos" :icon="icons.mdiVideoOutline ">
        <nav-menu-link title="Videos" :to="{ name: 'VideoList' }"></nav-menu-link>
        <nav-menu-link title="Categories" :to="{ name: 'VideoCategoryList' }"></nav-menu-link>
        <nav-menu-link title="Categories With Videos" :to="{ name: 'VideoCategoryListData' }"></nav-menu-link>
      </nav-menu-group>
      <!-- <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group> -->
      <nav-menu-section-title title="Other"></nav-menu-section-title>
      <!-- <nav-menu-link
        title="Feedback"
        :to="{ name: 'FeedbackList' }"
        :icon="icons.mdiChatProcessingOutline"
      ></nav-menu-link> -->
      <nav-menu-link title="Mobile Dashboard" :to="{ name: 'MobileDash' }" :icon="icons.mdiCellphone"></nav-menu-link>
      <nav-menu-link title="Video Dashboard" :to="{ name: 'VideoDash' }" :icon="icons.mdiAnimationPlay"></nav-menu-link>
      <!-- <nav-menu-link
        title="User Requests"
        :to="{ name: 'UserRequestList' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-link title="Payment" :to="{ name: 'PaymentList' }" :icon="icons.mdiCash"></nav-menu-link> -->
      <nav-menu-link title="Send Notification" :to="{ name: 'SendNotification' }" :icon="icons.mdiSendCircle">
      </nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiPackage,
  mdiAccountCogOutline,
  mdiChatProcessingOutline,
  mdiCellphone,
  mdiAccountGroupOutline,
  mdiCash, mdiAnimationPlay, mdiSendCircle,
  mdiVideoOutline
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiPackage,
        mdiAccountCogOutline,
        mdiChatProcessingOutline,
        mdiCellphone,
        mdiAccountGroupOutline,
        mdiCash,
        mdiVideoOutline, mdiSendCircle,
        mdiAnimationPlay
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
