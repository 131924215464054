import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
    },
  },
  {
    path: '/users',
    name: 'UserIndex',
    component: () => import('@/views/users/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'UserList',
        component: () => import('@/views/users/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Users | List',
        },
      },
      {
        path: 'list-expired',
        name: 'UserExpList',
        component: () => import('@/views/users/expiryList.vue'),
        meta: {
          requiresAuth: true,
          title: 'Users | Recently Expired',
        },
      },
      {
        path: 'up-exp-list',
        name: 'UserUpExpList',
        component: () => import('@/views/users/upcomingList.vue'),
        meta: {
          requiresAuth: true,
          title: 'Users | Upcoming Expires',
        },
      },
      {
        path: 'edit/:id',
        name: 'UserEdit',
        component: () => import('@/views/users/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Users | Edit',
        },
      },
    ],
  },
  {
    path: '/packages',
    name: 'PackageIndex',
    component: () => import('@/views/packages/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'PackageList',
        component: () => import('@/views/packages/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Packages | List',
        },
      },
      {
        path: 'edit/:id',
        name: 'PackageEdit',
        component: () => import('@/views/packages/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Packages | Edit',
        },
      },
    ],
  },
  {
    path: '/user-category',
    name: 'UserCategoryIndex',
    component: () => import('@/views/user_categories/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'UserCategoryList',
        component: () => import('@/views/user_categories/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Category | List',
        },
      },
      {
        path: 'edit/:id',
        name: 'UserCategoryEdit',
        component: () => import('@/views/user_categories/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Category | Edit',
        },
      },
      {
        path: 'add',
        name: 'UserCategoryAdd',
        component: () => import('@/views/user_categories/add.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Category | Add',
        },
      },
    ],
  },
  {
    path: '/user-sub-category',
    name: 'UserSubCategoryIndex',
    component: () => import('@/views/user_sub_categories/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'UserSubCategoryList',
        component: () => import('@/views/user_sub_categories/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Sub Category | List',
        },
      },
      {
        path: 'edit/:id',
        name: 'UserSubCategoryEdit',
        component: () => import('@/views/user_sub_categories/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Sub Category | Edit',
        },
      },
      {
        path: 'add',
        name: 'UserSubCategoryAdd',
        component: () => import('@/views/user_sub_categories/add.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Sub Category | Add',
        },
      },
    ],
  },
  {
    path: '/post-category',
    name: 'PostCategoryIndex',
    component: () => import('@/views/post_categories/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'PostCategoryList',
        component: () => import('@/views/post_categories/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post Category | List',
        },
      },
      {
        path: 'list-post',
        name: 'PostCategoryListData',
        component: () => import('@/views/post_categories/listData.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post Category | List',
        },
      },
      {
        path: 'edit/:id',
        name: 'PostCategoryEdit',
        component: () => import('@/views/post_categories/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post Category | Edit',
        },
      },
      {
        path: 'add',
        name: 'PostCategoryAdd',
        component: () => import('@/views/post_categories/add.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post Category | Add',
        },
      },
    ],
  },
  {
    path: '/video-category',
    name: 'VideoCategoryIndex',
    component: () => import('@/views/video_categories/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'VideoCategoryList',
        component: () => import('@/views/video_categories/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video Category | List',
        },
      },
      {
        path: 'list-video',
        name: 'VideoCategoryListData',
        component: () => import('@/views/video_categories/listData.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video Category | List',
        },
      },
      {
        path: 'edit/:id',
        name: 'VideoCategoryEdit',
        component: () => import('@/views/video_categories/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video Category | Edit',
        },
      },
      {
        path: 'add',
        name: 'VideoCategoryAdd',
        component: () => import('@/views/video_categories/add.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video Category | Add',
        },
      },
    ],
  },
  {
    path: '/posts',
    name: 'PostIndex',
    component: () => import('@/views/posts/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'PostList',
        component: () => import('@/views/posts/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post | List',
        },
      },
      {
        path: 'edit/:id',
        name: 'PostEdit',
        component: () => import('@/views/posts/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post | Edit',
        },
      },
      {
        path: 'add',
        name: 'PostAdd',
        component: () => import('@/views/posts/add.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post | Add',
        },
      },
      {
        path: 'add-multiple',
        name: 'PostAddMulti',
        component: () => import('@/views/posts/addMulti.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post | Add',
        },
      },
      {
        path: 'list-cat/:id',
        name: 'PostListId',
        component: () => import('@/views/posts/listId.vue'),
        meta: {
          requiresAuth: true,
          title: 'Post | List',
        },
      },
    ],
  },
  {
    path: '/videos',
    name: 'VideoIndex',
    component: () => import('@/views/videos/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'VideoList',
        component: () => import('@/views/videos/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video | List',
        },
      },
      {
        path: 'add',
        name: 'VideoAdd',
        component: () => import('@/views/videos/add.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video | Add',
        },
      },
      {
        path: 'add-multiple',
        name: 'VideoAddMulti',
        component: () => import('@/views/videos/addMulti.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video | Add',
        },
      },
      {
        path: 'preview/:id',
        name: 'VideoView',
        component: () => import('@/views/videos/view.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video | Preview',
        },
      },
      {
        path: 'edit/:id',
        name: 'VideoEdit',
        component: () => import('@/views/videos/edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video | Edit',
        },
      },
      {
        path: 'list-cat/:id',
        name: 'VideoListId',
        component: () => import('@/views/videos/listId.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video | List',
        },
      },
    ],
  },
  {
    path: '/feedback',
    name: 'FeedbackIndex',
    component: () => import('@/views/feedback/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'FeedbackList',
        component: () => import('@/views/feedback/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Feedback | List',
        },
      },
    ],
  },
  {
    path: '/user-request',
    name: 'UserRequestIndex',
    component: () => import('@/views/user_reqs/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'UserRequestList',
        component: () => import('@/views/user_reqs/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Request | List',
        },
      },
    ],
  },
  {
    path: '/payment',
    name: 'PaymentIndex',
    component: () => import('@/views/payment/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        name: 'PaymentList',
        component: () => import('@/views/payment/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Payment',
        },
      },
    ],
  },
  {
    path: '/notification',
    name: 'PaymentIndex',
    component: () => import('@/views/notification/send_notification.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'send',
        name: 'SendNotification',
        component: () => import('@/views/notification/send_notification.vue'),
        meta: {
          requiresAuth: true,
          title: 'Notifications',
        },
      },
    ],
  },
  {
    path: '/mobile-dashboard',
    name: 'MobileIndex',
    component: () => import('@/views/mobile/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'MobileDash',
        component: () => import('@/views/mobile/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Mobile Dashboard',
        },
      },
    ],
  },
  {
    path: '/video-dashboard',
    name: 'VideoIndex',
    component: () => import('@/views/video/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'VideoDash',
        component: () => import('@/views/video/list.vue'),
        meta: {
          requiresAuth: true,
          title: 'Video Dashboard',
        },
      },
    ],
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      onlyGuest: true,
      title: 'Login',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // console.log(to);
  document.title = to.meta.title
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // console.log('sdfa', store.state.User);
    store
      .dispatch('User/getAuth')
      .then(user => {
        if (user) {
          next()
        } else {
          next({
            name: 'Login',
          })
        }
      })
      .catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.message,
          type: 'warning',
        })
        store.commit('User/SET_LOGIN_SUCCESS_ROUTE', to.name)
        next({
          name: 'Login',
        })
      })
  } else if (to.matched.some(route => route.meta.onlyGuest)) {
    console.log(123)
    store.dispatch('User/setAuthFromLocal').then(user => {
      console.log(456)
      if (user && user.role == 'admin') {
        next({
          name: 'UserList',
        })
      } else {
        next()
        console.log(789)
      }
    })
  } else {
    if (store.getters['User/isFirstLoad']) {
      store.commit('User/SET_FIRST_LOAD_STATUS', false)
    }
    next()
  }
})

export default router
